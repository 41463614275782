/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.bg-light-purple {
    background-color: rgb(244, 224, 244);
    color: purple;
    border: 1px solid purple;
}
.bg-light-wine {
    background-color: rgb(227, 227, 248);
    color: rgb(71, 65, 246);
    border: 1px solid rgb(107, 103, 234);
}
.custom-date-cell {
    border-radius: 50%;
    padding: 10px;
}

.custom-date-cell {
    padding: 0;
}

.custom-date-cell .rbc-day-bg {
    position: relative;
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.custom-date-cell.absent .rbc-day-bg {
    background-color: red;
}

.custom-date-cell.weekend .rbc-day-bg {
    background-color: blue;
}

.custom-date-cell.present .rbc-day-bg {
    background-color: green;
}

.custom-event-color {
    background-color: #e5f8ed; /* Set your desired background color */
    // color: #28c76f; /* Set your desired text color */
    padding: 0px;
    border-radius: 3px;
    border: 1px solid #28c76f;
}

.fc-h-event {
    background-color: unset; /* Unset the background color property */
    border: unset; /* Unset the border property */
    display: unset; /* Unset the display property */
}

.small-holiday-name {
    width: 100px; /* Adjust the width as needed */
}

.menu-item1:hover {
    background-color: #dce0e0; /* Change the background color to the desired color on hover */
}
/* CSS for the rounded buttons */
.rounded-buttons .btn {
    border-radius: 20px; /* Adjust the border-radius as needed */
}

/* CSS for the selected button */
.selected {
    border-radius: 50%; /* Makes the selected button circular */
}

/* Additional styling for the selected button */
.selected.primary {
    background-color: #007bff; /* Change the background color for the selected primary button */
    color: #fff; /* Change the text color for the selected primary button */
}

.animated-card {
    // position: absolute;
    transition: transform 0.1s ease 0s;
    transform: translate(20%, 25%);
}

@media (min-width: 300px) {
    .animated-card {
        transform: translate(12%, 24%);
    }
}

@media (min-width: 600px) {
    .animated-card {
        transform: translate(10%, 20%);
    }
}

@media (min-width: 700px) {
    .animated-card {
        transform: translate(5%, 18%);
    }
}

@media (min-width: 900px) {
    .animated-card {
        transform: translate(5%, 15%);
    }
}

@media (min-width: 1100px) {
    .animated-card {
        transform: translate(8%, 12%);
    }
}

@media (min-width: 1300px) {
    .animated-card {
        transform: translate(10%, 10%);
    }
}

@media (min-width: 1500px) {
    .animated-card {
        transform: translate(12%, 8%);
    }
}

@media (min-width: 1700px) {
    .animated-card {
        transform: translate(2%, 5%);
    }
}

@media (min-width: 1900px) {
    .animated-card {
        transform: translate(2%, 2%);
    }
}

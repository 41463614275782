@import './base/bootstrap.scss';
@import './base/bootstrap-extended.scss';
@import './base/colors.scss';
@import './base/components.scss';

// Themes
@import './base/themes/dark-layout.scss';
@import './base/themes/bordered-layout.scss';
@import './base/themes/semi-dark-layout.scss';
@import './base/daypicker.scss';

// @import './base/custom-rtl.scss';

// React Specific
@import 'react/index';

.loader-component {
    background-color: rgb(248, 248, 248, 60%);
    position: fixed;
    z-index: 1000000;
}

.dark-layout {
    .loader-component {
        background-color: rgb(22, 29, 49, 60%);
    }
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
}

.my-custom-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -18px; /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 20px; /* gutter size */
    background-clip: padding-box;
    // margin-right: 1px;
}
.fc-theme-standard td,
.fc-theme-standard th {
    border: 1px solid;
}

.test {
    justify-content: flex-end;
}

/* Styles for the indicators */
.indicator {
    display: inline-block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    margin-right: 10px; /* Adjust spacing between indicator and text */
}

.high-indicator {
    background-color: #f62b2b;
}

.low-indicator {
    background-color: #28a828;
}

.medium-indicator {
    background-color: #ff7800;
}
/* Media query for screens under 1400px */
/* Default style for buttons in a row */

/* Apply a different layout when screen width is under 1400px */
.button-container {
    display: flex;
    flex-wrap: wrap;

    align-items: flex-start;
}

.button-container button {
    margin-bottom: 10px;
    margin-right: 10px;
}

@media screen and (min-width: 1400px) {
    .button-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .button-container button {
        display: flex;
        flex-direction: row;
        margin-right: 0px !important;
    }
}
@media screen and (min-width: 1700px) {
    .button-container {
        margin-left: -20px;
        flex-direction: row;
        justify-content: space-evenly;
    }
}

.my-datagrid .dx-datagrid {
    cursor: pointer;
}

/* For small screens (sm), max-width is 250px */
.custom-max-width {
    max-width: 200px;
}

/* For medium (md), large (lg), extra-large (xl), and extra-extra-large (xxl) screens, max-width is 300px */
/* For medium screens (md), max-width is 250px */

@media (max-width: 767px) {
    .custom-max-width {
        max-width: 200px;
    }
}
@media (min-width: 768px) {
    .custom-max-width {
        max-width: 250px;
    }
}

/* For large screens (lg), max-width is 280px */
@media (min-width: 992px) {
    .custom-max-width {
        max-width: 150px;
    }
}

/* For extra-large screens (xl), max-width is 300px */
@media (min-width: 1200px) {
    .custom-max-width {
        max-width: 200px;
    }
}

/* For extra-extra-large screens (xxl), max-width is 320px */
@media (min-width: 1600px) {
    .custom-max-width {
        max-width: 310px;
    }
}

.avtarheight {
    height: 42px;
}
.fiximg {
    object-fit: cover;
    object-position: top;
}

.selected-date-cell {
    background-color: #007bff; /* Change to your desired style */
    color: #fff; /* Change to your desired style */
}

.card-hover1:hover {
    box-shadow: 0px 0px 15px #839a9c;
    // transition: box-shadow 0.3s;
}

.fc-daygrid-event-dot {
    display: none;
}

.custom-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.custom-card:hover {
    transform: translateY(-5px);
}

.fixed-column {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    /* Adjust the background color as needed */
    z-index: 100;
    /* Adjust the z-index as needed */
}

.editor-error {
    border: 1px solid #ea5455 !important; /* Red border for invalid input */
}

.validation-error {
    color: #ea5455 !important; /* Red border for invalid input */
}

.list-group-flush {
    .draggable-list {
        border-radius: 6px;
        &:not(:last-child) {
            margin-bottom: 14px;
        }
    }
}

.btn-custom {
    box-shadow: none !important;
}
.btn-custom:hover {
    box-shadow: 0 8px 25px -8px $primary !important;
}

.custom-project-btn {
    border-radius: 4px;
    height: 30px;
    width: 30px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-reporting-grid .dx-datagrid .dx-row > td {
    padding: 0;
}

/* Ensure that background is removed for custom events and prevent FullCalendar's styles from overriding */
.fc-event.custom-calendar-event.fc-daygrid-event.fc-daygrid-dot-event.fc-event-mirror:hover,
.fc-event.custom-calendar-event.fc-daygrid-event.fc-daygrid-dot-event:hover {
    background: none !important;
}

.fc-event.custom-calendar-event.fc-daygrid-event.fc-daygrid-dot-event,
.fc-event.custom-calendar-event.fc-daygrid-event.fc-daygrid-dot-event.fc-event-mirror {
    background: none !important;
}

.lead-board {
    border-radius: 0px;
    border-right: 1px solid #ccc;
    margin-bottom: 10px;
}
@media (max-width: 575.98px) {
    .lead-board {
        border-right: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .lead-board {
        border-right: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .lead-board-sm-xs {
        border-right: 1px solid #ccc;
        margin-bottom: 10px;
    }
}

@media (min-width: 767.98px) and (max-width: 991px) {
    .lead-board-md {
        border-right: none;
    }
}

.team-col-gap {
    gap: 12px;
}

.custom-avatar-group img {
    height: 42px;
    width: 42px;
}

.custom-project-card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.custom-project-card:hover {
    transform: translateY(-5px);
}

.custom-project-dropdown {
    font-weight: 800 !important;
    font-size: 22px !important;
    border: none !important;
    padding-left: 0px !important;
    padding-top: 2px !important;
    padding-bottom: 4px !important;
}

.custom-project-dropdown:hover {
    background-color: inherit !important;
}
.custom-project-dropdown:active {
    background-color: inherit !important;
}
// .custom-project-dropdown .btn-outline-primary:not(:disabled):not(.disabled):active,
// .btn-outline-primary:not(:disabled):not(.disabled).active,
// .btn-outline-primary:not(:disabled):not(.disabled):focus {
//     background-color: inherit !important;
// }

.project-dropdown-label:hover {
    color: #1a3c3f;
    background-color: var(--bs-dropdown-link-hover-bg);
}
.selected-row {
    background-color: #4280ad !important; /* Light blue */
}

/* Default max-width for larger screens */
.project-badge {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 300px; /* Default maximum width for large screens */
}

/* Truncate name for screen size between 991px and 1600px */
@media (min-width: 991px) and (max-width: 1600px) {
    .project-badge {
        max-width: 180px; /* Adjust max-width to truncate after 13 characters */
    }
}

/* Truncate name for screens between 767px and 991px */
@media (min-width: 767.98px) and (max-width: 991px) {
    .project-badge {
        max-width: 120px; /* Adjust max-width to truncate after 8 characters */
    }
}

/* Truncate name for screens smaller than 767px */
@media (max-width: 767.98px) {
    .project-badge {
        max-width: 100px; /* Adjust max-width for smaller screens */
    }
}

//Project Details
.project-details {
    color: #1f2022;
    font-size: 14px;
    font-weight: 700;
}

.padding-custom {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px !important;
}

//project state details

.states {
    // color: #fff; /* Text color */
    padding: 20px;
    font-family: Arial, sans-serif;
}

.state-section {
    margin-bottom: 20px;
}

.state-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.state-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #444;
    border-radius: 5px;
}

.icon {
    font-size: 1.5rem;
    margin-right: 10px;
}

.item-label {
    font-size: 1rem;
    font-weight: bold;
}

.item-description {
    margin: 0;
    font-size: 0.85rem;
    color: #aaa; /* Muted text color */
}

.add-btn {
    background-color: transparent;
    border: 1px solid #666;
    color: #fff;
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1rem;
}

.add-btn:hover {
    background-color: #444;
}

//project accordian
.project-accordion .accordion .accordion-body {
    padding: 0px !important;
}

.custom-btn-dropdown {
    border-color: rgba(229, 229, 229) !important;
    font-size: 12px !important;
    font-weight: 800 !important;
    padding: 8px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.project-dropdown-item {
    font-size: 11px !important;
    font-weight: bold !important;
    display: flex !important;
    align-items: center !important;
}
.project-dropdown-header {
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 17px;
}
.project-dropdown-item-header {
    font-size: 12px !important;
    font-weight: bolder !important;
    color: black !important;
}

//Modal scss
.modal-lg-2 {
    --bs-modal-width: 60%;
}

.custom-badgess {
    background-color: red !important;
}

//
.project-dropdown-label:hover {
    background: #f7f7f7;
    cursor: pointer;
}

.bi {
    vertical-align: middle;
}

//custom-activity

.custom-activity-btn {
    background-color: rgba(130, 134, 139, 0.2);
    transition: all 0.3s ease;
}

// .custom-activity-btn:hover {
//     background-color: #1a3c3f !important;
//     color: #ffff !important;
// }

.custom-activity-btn-active {
    background-color: #1a3c3f !important;
    color: #ffff !important;
}

//Expense Filter
.reduce-left-space {
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.task-title.form-control:focus {
    box-shadow: none !important;
}

.task-dropdown .css-1rv6cxx-control {
    min-height: 28px !important;
}


.custom-input-textarea {
    box-shadow: none !important;
    border: none !important;
}

.custom-input-textarea.form-control {
    padding: 0px !important;
    min-height: 4rem !important;
}

.editable-text {
    outline: none;
    border: none;
    min-height: 20px;
    padding: 0;
    font-size: 16px;
    line-height: 1.5;
    white-space: pre-wrap; /* Keep line breaks if needed */
}

// list task
.task-hover:hover {
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
}
.list-accordion-body .accordion-body {
    padding: 0px;
}

//refresh icon css
.rotate {
    animation: spin 1s linear infinite;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn .rotate {
    border: 0px solid #f3e8e4 !important;
    background-color: #f3e8e4 !important;
}
.rotate-color {
    background-color: #f3e8e4;
}
.report .dx-icon.dx-icon-refresh {
    color: #9a4521 !important;
}
.create-task-dropdown .css-qbdosj-Input{
    display: none !important;
}


.sticky-button {
    position: sticky;
    bottom: 0;
    z-index: 10; 
    background: #f0f0f1; 
    border-radius: 0.428rem;
}
.btn-flat-secondary:hover:not(.disabled):not(:disabled).create-task-btn-hover{
    background-color: transparent !important;
}
// .create-task-btn-hover{
//     background-color:#e1e1e2  !important;
// }

.custom-chart-css .apexcharts-legend-text{
    padding-left: 0px !important;
}

